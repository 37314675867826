.why-choose-us {
    margin: 20px;
    padding: 0 10px;
}

.why-choose-us h2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.item {
    margin-bottom: 10px;
}

.title_whe {
    background-color: #0077b6;
    border-color: #0077b6;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.symbol {
    width: 15px;
    height: 15px;
    background-color: white;
    text-align: center;
    line-height: 10px;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0077b6;
}

.text {
    flex-grow: 1;
    text-align: left;
    font-size: 1em;
}

.arrow {
    margin-left: 10px;
}

.content_whe p{
    padding-top: 10px;
}

@media (max-width: 768px) {
    .why-choose-us {
        margin: 0;
        padding: 0;
    }

    .title_whe {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }

    .symbol {

    }

    .text {
        font-size: 1.2em;
        text-align: left;
    }

    .arrow {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .why-choose-us {

    }

    .title_whe {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .text {
        font-size: 1.1em;
    }
}
