
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s;

}



.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.7);
}

.top_navbar_fon {
    background-color: #000;
    opacity: 0.7;
    color: white;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #515151;
}

.top_navbar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5em 1em;
    height: 45px;
}

.social-media {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-media li {
    margin-right: 10px;
    cursor: pointer;
}

.social-media li i {
    color: white;
    font-size: 14px;
}

.contact-info {
    display: flex;
}

.contact-info ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.contact-info ul li {
    margin-left: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.contact-info ul li i {
    margin-right: 12px;
}

.navbar_fon {
    background-color: #000;
    opacity: 0.7;
    height: 80px;
}


.navbar_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em;

}

.navbar_logo {
    font-size: 24px;
    font-weight: bold;
    color: #023e8a; /* Matching the yellow color in the logo */
}

.navbar_menu {
    display: flex;
    align-items: center;
}

.navbar_menu a {
    color: white;
    text-decoration: none;
    padding: 0 15px;
    font-size: 14px;
    position: relative;
}

.navbar_menu a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #0077b6; /* Matching the yellow color */
    transition: width .3s;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.navbar_menu a:hover::after {
    width: 100%;
}

.navbar_menu a.active {
    color: #0077b6; /* Matching the yellow color */
}

.navbar_right {
    display: flex;
    align-items: center;
}

.cart-icon {
    font-size: 20px;
    color: white;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
}

.navbar_toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: white;
}

.container_navbar img {
    width: 166px;
}

@media (max-width: 768px) {
    .contact-info ul li {
        font-size: 6px;
    }

    .navbar_menu {
        display: none;
        flex-direction: column;
        background-color: #2e2e2e;
        position: fixed;
        top: 70px;
        right: 0;
        width: 100%;
        height: calc(100vh - 70px);
        z-index: 999;
        text-align: center;
        padding-top: 2em;
    }

    .navbar_menu.open {
        display: flex;
    }

    .navbar_menu a {
        margin: 1em 0;
        font-size: 1.2em;
    }

    .navbar_toggle {
        display: block;
    }
}


