.EXPANSION_PROJECTS {
    background-image: url("/src/components/Img/slider/18.webp");
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.EXPANSION_PROJECTS h1 {
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.title_1 {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.title_2 {
    padding: 50px 0;
}

.content_ep {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
}

.project-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-content_ep {
    max-width: 600px;
}

.content_ep img {
    width: 100%;
    height: 400px;
}

.text-content_ep h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.text-content_ep p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.text-content_ep ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.text-content_ep li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.titl_img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    padding-bottom: 50px;
}

.titl_img img {
    width: 100%;
    height: 300px;
}

.titl_img_text {
    width: 100%;
}

.title_img_title {
    padding: 0 0 40px 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .content_ep {
        gap: 2rem;
    }

    .text-content_ep h2 {
        font-size: 1.75rem;
    }

    .text-content_ep p, .text-content_ep li {
        font-size: 1.1rem;
    }

    .content_ep img {
        height: 300px;
    }

    .titl_img img {
        height: 250px;
    }
}

@media (max-width: 768px) {
    .content_ep {
        flex-direction: column;
        align-items: center;
    }

    .text-content_ep {
        max-width: 100%;
    }

    .content_ep img {
        height: auto;
    }

    .titl_img {
        flex-direction: column;
        gap: 1rem;
    }

    .titl_img img {
        height: auto;
    }

    .text-content_ep h2 {
        font-size: 1.5rem;
    }

    .text-content_ep p, .text-content_ep li {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .EXPANSION_PROJECTS h1 {
        font-size: 2rem;
    }

    .text-content_ep h2 {
        font-size: 1.25rem;
    }

    .text-content_ep p, .text-content_ep li {
        font-size: 0.9rem;
    }

    .titl_img {
        gap: 0.5rem;
    }

    .title_img_title {
        padding: 20px 0 20px 0;
    }
}
