.hero_fon {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    transition: background-image 1s ease-in-out, opacity 1s ease-in-out;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 1s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.animate-text {
    animation: moveDown 2s forwards;
}

.animate-button {
    animation: moveUp 2s forwards;
}

@keyframes moveDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.hero_fon a {
    background-color: #023e8a;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none;
    margin-top: 25px;
}

.hero_fon a:hover {
    background-color: #0077b6;
}
