/* General Styles */
.service-box {
    transition: transform 0.5s;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}

.service-box:hover {
    transform: scale(1.05);

}

.main_server_text h4{
    padding-bottom: 15px;
}

.icon {
    width: 70px;
    height: 70px;
    line-height: 60px;
    background-color: #023e8a;
    color: #ffffff;
    border-radius: 50%;
    font-size: 2rem;
    transition: all 0.5s;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.service-box:hover .icon {
    color: #000000;
    transform: rotate(360deg);
    background-color: white;
    border: 1px solid #0077b6;
}

.icon + div {
    margin-left: 15px;
}

.row_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.title_MainServer {
    padding-bottom: 50px;
    font-size: 36px;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .row_section {
        flex-direction: column;
        align-items: flex-start; /* Align items to the start for better readability */
    }

    .service-box {
        padding-bottom: 30px;
    }

    .icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .title_MainServer {
        font-size: 32px;
    }
}

@media (max-width: 768px) {
    .service-box {
        padding-bottom: 20px;
        align-items: center;
        gap: 20px;
    }

    .icon {
        width: 50px;
        height: 50px;
        font-size: 1.25rem;
    }

    .icon + div {
        margin-left: 0;
        margin-top: 10px;
    }

    .title_MainServer {
        font-size: 28px;
        padding-bottom: 30px;
        text-align: left;
    }
}

@media (max-width: 576px) {
    .icon {
        width: 40px;
        height: 40px;
        font-size: 1rem;
    }

    .title_MainServer {
        font-size: 24px;
        text-align: left;
    }
}
